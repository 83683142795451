import React from "react";
import {Link} from "gatsby";

import Layout from "../../components/layout";
import {ApiDocSidebar} from "../../components/sidebar";
import {ApiLink} from "../../components/link";
import {SampleProjects} from "../../components/sample-projects";
import {KeyHeader} from "../../components/typography";

const pageTitle = "Documentation";

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<ApiDocSidebar/>}
            mobileNav={false}
    >
        <KeyHeader className="mt-0">Documentation</KeyHeader>

        <p>
            This section is useful when you want to learn more details about a particular SQLAPI++ API,
            compile/linking option or server-specific feature or limitation.
        </p>

        <h3>Compiling and Linking</h3>
        <p>
            The {' '}<Link to="/ApiDoc/build-app/">Compiling & Linking Apps</Link>{' '}
            section explains how to compile and link your applications with SQLAPI++,
            as well as your application's runtime dependencies.
        </p>
        <p>
            The {' '}<Link to="/ApiDoc/build-lib/">Building SQLAPI++</Link>{' '} section
            explains how to rebuild the library from sources in your environment.
            This section is not applicable for trial version of SQLAPI++ which comes with all libraries prebuilt.
            For production use, however, we strongly recommend that you recompile from the sources included to achieve
            maximum compatibility with your environment.
        </p>

        <h3>API Documentation</h3>
        <p>
            Explore detailed {' '}<ApiLink annotated={true}>API documentation</ApiLink>{' '}
            and {' '}<ApiLink>SQLAPI.h</ApiLink>{' '} header reference.
        </p>

        <h3>Database servers specific information</h3>
        <p>
            The {' '}<Link to="/ApiDoc/servers/">Server Specific Guides</Link>{' '} section
            explains features and limitations specific to each of the supported servers.
            For the most part programs written with SQLAPI++ are portable across all supported servers. However,
            each server has specific additional features that your program can take advantage of.
        </p>

        <KeyHeader>Sample Projects</KeyHeader>
        <SampleProjects/>
    </Layout>
);

export default Page;
